<template>
  <div class="user-container">

    <div class="padding-b border-b">
      <div class="row padding-b">

        <img v-show="computedAvatar != null" class="avatar-container" ref="avatar"  style="width:100px">
        <!-- <div class="avatar" :style="{'background-image': 'url(' + computedAvatar + ')'}">
        </div> -->
        <p v-show="computedAvatar == null" class="avatar-container"> {{cmptGetCurrentUserInitials}} </p>

        <div class="user-title">
          <h1>{{cmptUserData.givenName}} {{cmptUserData.surname}}</h1>
          <p>{{cmptUserData.companyName}}</p>
        </div>
      </div>

      <div class="row edit-btn-row">
        <button @click="editProfile = true, changePW = false" class="btn-standard btn-white margin-r">Edit Profile</button>
        <button @click="changePW = true, editProfile = false" class="btn-standard btn-white"> <!-- <SystemIcons :type="'lockIcon'" class="x-icon"/> --> Change Password </button>
      </div>
    </div>

    <div v-if="!editProfile && !changePW" class="block border-b">
      <!-- <p>{{cmptUserData}}</p> -->

      <p> {{cmptUserData.givenName}} {{cmptUserData.surname}} </p>
      <p> {{cmptUserData.displayName}} </p>
    </div>

    <button v-if="editProfile" style="width:100px" @click="onDeleteAvatar"> Delete Avatar</button>
    <input  v-if="editProfile" type="file" @change="onAvatarSelected" name="avatarFile">
    <!-- <input  type="file" ref="file" class="btn-standard" @click="updateUserImage"> -->

     <!-- <input type="file"
       ref="file"
       :name="uploadFieldName"
       @change="onFileChange(
          $event.target.name, $event.target.files)"
       style="display:none"> -->

    <div v-if="editProfile" class="edit-profile-container block border-b">

      <label for="eMail">Email</label>
      <input v-model="cmptUpdateableUser.eMail" type="eMail" name="Enter eMail" placeholder="eMail" id="eMail" disabled>
      <label for="givenName">Given name</label>
      <input v-model="cmptUpdateableUser.givenName" type="text" name="Enter given name" placeholder="givenName" id="givenName">
      <label for="surname">Surname</label>
      <input v-model="cmptUpdateableUser.surname" type="text" name="Enter surname" placeholder="surname" id="surname">

      <label for="displayName">displayName</label>
      <input v-model="cmptUpdateableUser.displayName" type="text" name="Enter displayName" placeholder="displayName" id="displayName">

      <!-- <label for="role">role</label>
      <select v-model="cmptUpdateableUser.role" id="role" @change="warningText =null">
        <option disabled value="">Please select one</option>
        <option
          v-for="(item, index) in cmptUserRoles"
          :key = index
          :value="item.id"
        >{{ item.name }}</option>
        <option disabled="disabled">----------</option>
        <option value="-1" style="color:grey">None</option>
      </select> -->

      <div class="row margin-t padding-b">
        <button @click="editProfile = false; updateableUser = null" class="btn-standard btn-white margin-r">Cancel</button>
        <div class="spread"></div>
        <button @click="onSaveEditedUser" class="btn-standard btn-white margin-r">Save Changes</button>
      </div>
    </div>

    <div v-if="changePW" class="edit-profile-container block border-b">
      <input v-model="oldPwd" :type="pwdDisplayType" name="old-pw" placeholder="old password" id="">
      <input v-model="newPwd1" :type="pwdDisplayType" name="new-pw" placeholder="new password" id="" @keydown="warningText =null">
      <input v-model="newPwd2" :type="pwdDisplayType" name="repeat new-pw" placeholder="repeat new password" id="" @keydown="warningText =null">

       <button @click="togglePasswordDisplay" >{{pwdDisplayText}}</button>

      <span v-if="warningText" style="color:red">{{ warningText }}</span>

      <div class="row margin-t padding-b">
        <button @click="onCancelChangePWDs()" class="btn-standard btn-white margin-r">Cancel</button>
        <div class="spread"></div>
        <button @click="onChangePWD()" class="btn-standard btn-white margin-r">Change Password</button>
      </div>
    </div>

    <div class="row block">
      <p class="">My role</p>
      <p
        v-if="cmptUserRoleName"
        class="lable-role"
        :style="getRoleColor(cmptUserRoleName)"
        >
        {{cmptUserRoleName}}
      </p>
    </div>

  </div>
</template>

<script>
import SystemIcons from '@/components/elements/SystemIcons.vue'

export default {
  name: 'User',

  components: {
    SystemIcons
  },

  data () {
    return {
      avatar: null,
      editProfile: false,
      changePW: false,
      updateableUser: null,
      oldPwd: null,
      newPwd1: null,
      newPwd2: null,
      warningText: null,
      pwdDisplayType:"password",
      pwdDisplayText:"Show Passwords",
    }
  },

  computed: {
    computedAvatar(){
      return this.avatar
    },
    cmptUserData(){
      return this.$store.getters.getCurrentUser
    },
    cmptUpdateableUser(){
       if(this.updateableUser == null) {
        this.updateableUser = { ...this.$store.getters.getCurrentUser }
      }
      return this.updateableUser
    },
    cmptUserRoles(){
      return this.$store.getters.getUserRoles.filter( r => r.creatorCompanyId == this.cmptUserData.companyId)
    },
    cmptUserRoleName(){
      return this.$store.getters.getCurrentUserRole.name || ""
    },
    cmptGetCurrentUserInitials () {
      return this.$store.getters.getCurrentUserInitials
    }
  },

  methods: {
    getRoleColor(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
          return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      let hsl = `hsl(${stringUniqueHash % 360}, 95%, 75%)`
      return `background-color: ${hsl}`;
    },

    onSaveEditedUser(){
      this.editProfile = false
      this.$store.dispatch('updateUser', this.cmptUpdateableUser)
      this.$store.dispatch('setUserCurrentUser', this.cmptUpdateableUser)
      this.updateableUser = null
    },

    onAvatarSelected(evt){
      evt.preventDefault()
      this.displaySelectedUserImage()
    },
    onDeleteAvatar(evt){
      evt.preventDefault()
      this.avatar = null
      document.querySelector('[type=file]').value = ""
    },

    displaySelectedUserImage () {
      const files = document.querySelector('[type=file]').files
      const formData = new FormData()
      console.log('files', files)

      //Should only be one file
      for (var i = 0, f; f = files[i]; i++) {
        // regard only images
        if (!f.type.match('image.*')) {
          continue
        }

        //if(f.size > 2097152){  //Max 2 MB
        if(f.size > 3145728){  // Max 3 MB
          alert("File is too big! Max 3 MB");
          return
        };

        var reader = new FileReader()
        reader.onload = this.loadAvatar
        // Bilder als Data URL auslesen.
        reader.readAsDataURL(f)
      }

      //   reader.onload = function(event) {
      //     var picDisplay = this.myAvatarElem
      //     console.log("Showit picDisplay ", picDisplay);
      //     picDisplay.src = event.target.result
      //     // this.myAvatarElem = event.target.result
      //   }
      //   // Bilder als Data URL auslesen.
      //   reader.readAsDataURL(f)
      // }
    },

    loadAvatar(event) {
      this.avatar = event.target.result
      this.$refs.avatar.src = event.target.result
      this.updateUserImage()
    },

    async updateUserImage (/*evt*/) {  //Send picture to server
      //console.log('Event', evt)
      // evt.preventDefault()

      const files = document.querySelector('[type=file]').files
      // console.log('Uploading files', files)
      // Files object should only have one file
       var imageFile = files[0];

      //console.log('Uploading files -> imageFile', imageFile)
      if ((imageFile == null) || (!imageFile.type.match('image.*'))) {
        alert("No Image File selected!")
        return false;
      }


      var formData = new FormData();
      // formData.append("Title", this.userFields.userTitle);
      formData.append("avatar", imageFile);
      formData.append("userId", this.cmptUserData.id);
      formData.append("companyId", this.cmptUserData.companyId);


      //Store the image..
      await this.$store.dispatch('updateUserImage', formData)
      return true
    },

    togglePasswordDisplay(){
      if(this.pwdDisplayType == "password"){
        this.pwdDisplayType = "text"
        this.pwdDisplayText = "Hide Passwords"
      }
      else{
        this.pwdDisplayType = "password"
        this.pwdDisplayText = "Show Passwords"
      }
    },

    async onChangePWD(){
      if(!this.validatePWDs())
        return

      // this.editProfile = false
      // this.$store.dispatch('updateUser', this.cmptUpdateableUser)
      // this.$store.dispatch('setUserCurrentUser', this.cmptUpdateableUser)
      // this.updateableUser = null
      let changeObj = {userId: this.cmptUserData.id, oldPwd: this.oldPwd, newPwd: this.newPwd1}

      await this.$store.dispatch('changeUserPWD', changeObj)

      //After...
      this.clearPWDs()
      this.changePW = false
    },

    onCancelChangePWDs(){
      this.clearPWDs()
      this.changePW = false
    },
    validatePWDs(){

      if((this.oldPwd == null) || (this.oldPwd.length == 0)){
        this.warningText = "Enter the old password"
        return false
      }

      if((this.newPwd1 == null) || (this.newPwd1.length == 0)){
        this.warningText = "Enter the new password"
        return false
      }
      if((this.newPwd2 == null) || (this.newPwd1.length != this.newPwd2.length)){
        this.warningText = "The new password doesn't match"
        return false
      }

      return true
    },
    clearPWDs(){
      this.oldPwd = null
      this.newPwd1 = null
      this.newPwd2 = null
      this.warningText = null
    }
  },

  mounted() {
    if(this.$store.getters.getUserRoles.length == 0)
      this.$store.dispatch('fetchAllUserRoles')
  },
  beforeUnmount() {}
}
</script>

<style scoped lang="scss">
$avatar-container-size: 5rem;

.user-container {
  text-align: center;
  max-width: $max-width-small;
  padding: 5rem 0;
  margin: 0 auto;
  text-align: left;

  .avatar-container {
    background-color: var(--bg-secondary);
    color: var(--accent-primary);
    min-width: $avatar-container-size;
    width: $avatar-container-size;
    max-width: $avatar-container-size;
    min-height: $avatar-container-size;
    height: $avatar-container-size;
    max-height: $avatar-container-size;

    border-radius: 50%;
    margin-right: 2rem;
    font-weight: 800;
    font-size: 2rem;

    @include flexbox;
    @include align-items(center);
    @include flex-just(center);
  }

  label, .lable {
    margin-bottom: .5rem;
  }

  input {
    margin-bottom: 1rem;
  }

  .edit-profile-container {
    padding: 2rem 0;

    @include flexbox;
    @include flex-direction(column);
  }

  .edit-btn-row {
    margin-left: $avatar-container-size + 2rem;
  }

  .border-b {
    border-bottom: 1px solid var(--color-tertiary);
  }

  .block {
    padding: 2rem 0;
  }

  .lable-role {
    // background-color: var(--accent-secondary);
    // color: var(--bg-secondary);
    font-weight: 800;
    padding: .2rem .75rem .30rem .75rem;
    margin-left: 1rem;
    border-radius: .25rem;
    width: fit-content;
  }

  .padding-b {
    padding-bottom: 2rem;
  }
  .margin-t {
    margin-top: 2rem;
  }
  .margin-r {
    margin-right: .5rem;
  }

  // .row {
  //   @include flexbox;
  //   @include align-items(center);
  // }
}
</style>